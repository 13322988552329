<template>
  <b-card body-class="p-0">
    <div class="flex">
      <div class="w-[64%] border-r p-6">
        <div class="flex items-center h-full">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1 rounded-lg p-0"
            @click="$router.push({ name: 'dashboard-whatsapp' })"
          >
            <feather-icon
              size="2x"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h4 class="font-bold text-black d-inline-flex mb-0">
            Notifikasi WhatsApp Premium
          </h4>
        </div>
      </div>
      <div class="w-[36%]">
        <div class="bg-[#FFF2E2] flex gap-4 h-full items-center p-[12px] rounded-r-md">
          <img
            class="w-8"
            src="https://storage.googleapis.com/komerce/assets/icons/danger-yellow.svg"
            alt="warning"
          >
          <div class="text-black">
            Pedoman untuk mencegah no WA kamu terblokir saat menggunakan fitur ini <a
              href="https://bantuan.komerce.id/id/article/pedoman-agar-mencegah-no-wa-kamu-terblokir-saat-menggunakan-fitur-notifikasi-whatsapp-premium-uy5229/?bust=1686877558855"
              target="_blank"
              style="color: #08A0F7; text-decoration: underline;"
            >pelajari selengkapnya</a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="w-[64%] border-r p-6 flex flex-col items-center text-black space-y-8">
        <img
          class="max-w-[250px]"
          src="https://storage.googleapis.com/komerce/assets/illustration/banner-login-wa.svg"
          alt="wa-illustration"
        >
        <div class="text-center text-[20px] font-bold">
          <div>Terima kasih sudah mengaktifkan fitur</div>
          Notifikasi WhatsApp Premium
        </div>
        <div class="text-center">
          Fitur notifikasi WhatsApp otomatis aktif dengan template notif dari komship. Yuk hubungkan WhatsApp kamu untuk melanjutkan penyesuaian dan buat template notifikasimu sendiri untuk menunjukkan identitas bisnismu
        </div>
      </div>
      <div class="w-[36%] p-6 flex flex-col items-center text-black space-y-8">
        <div>Scan QR di bawah ini menggunakan aplikasi WhatsApp kamu</div>
        <div class="">
          <b-overlay
            variant="light"
            :show="loadingQr"
            spinner-variant="primary"
            blur="0"
            opacity=".5"
            rounded="sm"
            class="w-[200px] h-[200px]"
          >
            <qrcode-vue
              :value="qrCode"
              size="200"
            />
          </b-overlay>
        </div>
        <div
          v-if="phoneNumber"
          class="mt-0"
        >
          <div class="my-[10px] text-center font-bold text-[18px]">
            {{ phoneNumber }}
          </div>
        </div>
        <ol>
          <li class="flex space-x-6">
            <div>1.</div>
            <div>Buka WhatsApp pada telepon Anda dan pastikan scan QR menggunakan nomor yang telah Anda tambahkan</div>
          </li>
          <li class="flex space-x-5">
            <div>2.</div>
            <div>Klik Menu atau Setelan dan pilih Perangkat Tertaut/Linked Device/WhatsApp Web</div>
          </li>
          <li class="flex space-x-5">
            <div>3.</div>
            <div>Klik Tautkan Perangkat</div>
          </li>
          <li class="flex space-x-5">
            <div>4.</div>
            <div>Arahkan telepon kamu ke layar ini untuk memindai kode tersebut</div>
          </li>
        </ol>
      </div>
    </div>
    <b-modal
      id="modal-add-number"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      size="md"
    >
      <div style="padding: 21px 23px;">
        <div
          class="font-bold text-black text-2xl text-center"
          style="margin-bottom: 12px;"
        >
          Tambah Nomor Whatsapp
        </div>
        <div
          class="text-black text-lg text-center"
          style="margin-bottom: 20px;"
        >
          Yuk masukkan Nomor HP kamu yang akan dihubungkan ke fitur Notif WhatsApp
        </div>
        <b-input-group
          prepend="+62"
          style="margin-bottom: 8px;"
        >
          <b-form-input
            v-model="inputPhoneNumber"
            :formatter="formatPhoneNumber"
            @input="inputError = ''"
          />
        </b-input-group>
        <div
          class="text-sm"
          style="margin-bottom: 32px;"
        >
          <div :class="inputError === '' ? 'text-black' : 'text-[#E31A1A]'">
            {{ inputError === '' ? '*Minimal 9 digit berupa angka & wajib diawali dengan angka 8' : inputError }}
          </div>
        </div>
        <div class="d-flex gap-10">
          <b-button
            variant="outline-primary"
            class="w-full"
            @click="$router.push({ name: 'dashboard-whatsapp' })"
          >
            Batal
          </b-button>
          <b-button
            :variant="inputPhoneNumber.length < 9 || inputError === '*Nomor yang Anda masukkan tidak terhubung dengan WhatsApp' || inputError === '*Maaf, nomor yang Anda masukkan sudah terdaftar' || isLoading ? 'secondary' : 'primary'"
            class="w-full"
            :disabled="inputPhoneNumber.length < 9 || inputError === '*Nomor yang Anda masukkan tidak terhubung dengan WhatsApp' || inputError === '*Maaf, nomor yang Anda masukkan sudah terdaftar' || isLoading"
            @click="checkNumber()"
          >
            <span v-if="!isLoading">Simpan</span>
            <BSpinner
              v-if="isLoading"
              small
              label="Loading..."
            />
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { newAxiosIns } from '@/libs/axios'
import { BSpinner } from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'Koneksi',
  components: { BSpinner, QrcodeVue },
  data() {
    return {
      loadingQr: false,
      qrCode: '',
      connected: false,
      defaultWa: null,
      phoneNumber: null,
      phoneNumberId: null,
      inputPhoneNumber: '',
      inputError: '',
      serverWAFull: true,
      setScanWap: false,
      setScanKomchat: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('whatsapp', ['check_wa', 'phone_number']),
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.checkServer()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    async checkNumber() {
      this.disabledCheckNumber = true
      const url = '/v1/setting/check-number'
      const params = {
        phone_number: `0${this.inputPhoneNumber}`,
      }
      this.isLoading = true
      await this.$http_komship.get(url, { params })
        .then(res => {
          const { code } = res.data
          if (code === 200) {
            this.postNumber()
          } else if (code === 1001) {
            this.inputError = '*Maaf, nomor yang Anda masukkan sudah terdaftar'
            this.isLoading = false
          } else if (code === 1002) {
            this.inputError = '*Nomor yang Anda masukkan tidak terhubung dengan WhatsApp'
            this.isLoading = false
          }
          this.disabledCheckNumber = false
        })
        .catch(err => {
          this.disabledCheckNumber = false
        })
    },
    async postNumber() {
      const url = '/v2/wap/add-number'
      this.isLoading = true
      await this.$http_komship.post(url, {
        partner_id: this.profile.partner_id,
        phone_number: `0${this.inputPhoneNumber}`,
      })
        .then(async res => {
          const { code } = res.data
          if (code === 200) {
            this.$bvModal.hide('modal-add-number')
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Nomor kamu berhasil tersimpan',
                variant: 'success',
              },
            }, 500)
            this.phone_number.forEach(item => {
              if (item.phone_number === `0${this.inputPhoneNumber}`) {
                this.phoneNumberId = item.id
                this.phoneNumber = item.phone_number
              }
            })
            this.$router.push({ name: 'dashboard-whatsapp' })
          }
        })
        .catch(err => {
          this.$router.push({ name: 'dashboard-whatsapp' })
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal Menyimpan Nomormu',
              variant: 'danger',
            },
          }, 500)
        })
    },
    formatPhoneNumber(value) {
      const numericValue = value.replace(/[^\d]/g, '')
      if (numericValue.startsWith('62')) {
        return numericValue.substr(2)
      }
      if (numericValue[0] !== '8') {
        return numericValue.slice(1)
      }
      return numericValue.substr(0, 15)
    },
    async checkWA() {
      this.defaultWa = this.check_wa.is_notification_whatsapp
      if (this.defaultWa !== 2) {
        this.$router.push({ name: 'opsional-feature' })
      } else if (this.$route.params.number === undefined) {
        this.$bvModal.show('modal-add-number')
      } else {
        this.phoneNumber = this.$route.params.number.phone_number
        this.phoneNumberId = this.$route.params.number.id
        this.getQRCode()

        this.interval = setInterval(() => {
          this.getQRCode()
        }, 13000)
      }
    },
    getQRCode() {
      this.loadingQr = true
      const typeScan = this.$route.params.type
      const num = this.$route.params.number.id
      const numScan = this.$route.params.number.is_scan
      this.setScanWap = this.$route.params.scanWap
      this.setScanKomchat = this.$route.params.scanKomchat
      console.log(this.$route.params)

      if (!this.connected) {
        newAxiosIns.get(`/komship/api/v1/whatsapp/generate-qr/${typeScan}/${num}`)
          .then(res => {
            this.loadingQr = false
            const { data, meta } = res.data
            if (meta.code === 201) {
              this.connected = true
              this.$router.push({ name: 'dashboard-whatsapp' })
              if (typeScan === 'komchat') {
                this.$route.params.scanKomchat = true
              } else {
                this.$route.params.scanWap = true
              }
              this.$toast_success({ message: 'Selamat, nomor kamu berhasil terhubung. Kamu bisa mengaktifkan notifikasi pengiriman dan broadcast' })
            }
            this.qrCode = data.qr_code
          })
          .catch(() => {
            this.$router.push({ name: 'dashboard-whatsapp' })
            this.$toast_success({ message: 'Selamat, nomor kamu berhasil terhubung' })
          })
      } else {
        this.$router.push({ name: 'dashboard-whatsapp' })
      }
    },
    checkServer() {
      this.$http_komship.get('/v2/wap/check/server')
        .then(res => {
          const { data } = res.data
          this.serverWAFull = data.is_server_full
          if (this.serverWAFull) {
            this.$swal({
              title: 'Server Penuh',
              html: 'Silahkan tunggu 1-3 jam untuk kami menyiapkan layanan ini untukmu. Hubungi live chat jika lebih dari 3 jam notifikasi Whatsapp kamu belum siap',
              width: 500,
              imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
              confirmButtonText: 'Mengerti',
              confirmButtonClass: 'btn btn-primary',
              reverseButtons: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(response => {
              if (response.isConfirmed) {
                this.$router.push('/opsional-feature/notifikasi-wa-premium')
              }
            })
          } else {
            this.checkWA()
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Server penuh, Tunggu beberapa saat lagi',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
